<template>
  <div class="container">
    <header-back name='立即提现'></header-back>
    <section class="top44">
      <div class="with-m">
        <van-cell
          custom-class='card'
          is-link
          to="/bankCard"
          v-if="card"
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-icon
                name="card"
                size='0.6rem'
                :color="variables.textColor"
              /> &nbsp;{{card}}
            </div>
          </div>

        </van-cell>
        <van-cell v-else>
          <div slot="title">
            <div class="van-cell-text">
              <van-icon
                name="card"
                size='0.6rem'
              /> &nbsp;您还未绑定银行卡
            </div>
          </div>
          <div slot="right-icon">
            <van-button
              type="primary"
              :color="variables.textColor"
              round
              size="small"
              @click="onCard"
            >立即绑卡</van-button>
          </div>
        </van-cell>
      </div>

      <div class="with-c">
        <van-cell-group :border='false'>
          <template #title>
            <span
              class="custom-title"
              style="margin-right:8px"
            >提现金额</span>
            <van-popover
              v-model="showPopover"
              trigger="click"
              placement="bottom-start"
            >
              <div class="with-popover">
                <p>提现规则</p>
                <ul>
                  <li>1、提现金额≥100元，且须为整数；</li>
                  <li>2、当天兑换加款不可提现；需在第四日08：00至13：00申请提现;</li>
                  <li>3、打款仅每周工作日进行，周末及法定节假日打款顺延；</li>
                  <li>4、每天13：00到次日08：00点之间不可提现，避免和当日结算单产生差异；</li>
                </ul>
              </div>
              <template #reference>
                <van-icon
                  name="question-o"
                  color="#faad14"
                />
              </template>
            </van-popover>

          </template>
          <van-field
            v-model="valueMoney"
            center
            clearable
            label="￥"
            label-width='0.6rem'
            type="digit"
            :placeholder="'最高可提现'+allMOney+'元'"
            @input="onMoneyChange"
          >

            <template #button>
              <span
                class="with-span"
                @click="onAllMoney"
              >全部</span>
            </template>

          </van-field>
        </van-cell-group>
        <van-cell class="with-c-t">
          <template #title>
            <span class="with-c-money">冻结金额：<strong>{{staticFreezeBalance}}</strong> </span>

          </template>
          <template #right-icon>
            <span class="with-c-rt">提现金额需>=100</span>
          </template>

        </van-cell>
      </div>
      <!-- <div class="with-img">
        <img src="../../assets/image/card_banner.png" @click="onCardClick">
      </div> -->
      <div class="with-btn">
        <van-button
          :color="variables.textColor"
          round
          type="primary"
          :disabled='isDisable'
          block
          @click="onSumClick"
        >确认提现</van-button>
      </div>
      <!-- <div
        class="with-c"
        v-if="cardList.length>0"
      >
        <van-cell-group title="提现记录">
          <van-cell
            :label='item.label'
            v-for="(item,index) in cardList"
            :key='index'
          >
            <div
              slot="title"
              class="custom-title"
            >
              <van-icon
                name="printer"
                size='0.6rem'
                color="#FF7E00 "
              /> &nbsp;{{item.title}}
            </div>
            <div slot="right-icon">
              <span class="with-span">{{item.value}}</span>
            </div>
          </van-cell>
        </van-cell-group>

      </div> -->
      <div class="with-c">
        <van-cell
          title="提现记录"
          is-link
          value=""
          to='/withdrawRecord'
        />
      </div>
    </section>
    <van-overlay
      :show="show"
      @click="show = false"
    >
      <div class="wrapper">
        <div
          class="block"
          @click.stop
        >
          <div class="wrp-tit">选择提现方式</div>
          <div class="wrp-cen">
            <van-radio-group v-model="radio">
              <van-cell-group :border='false'>
                <van-cell
                  v-for="(item,index) in typsList"
                  :key='index'
                  clickable
                  @click="radioClick(item)"
                >
                  <template #title>
                    <van-image
                      width="24"
                      height="24"
                      :src="item.img"
                      v-if="item.img"
                    />
                    <van-icon
                      v-else
                      :name="item.icon"
                      size='0.5rem'
                    />
                    <span class="custom-title"> &nbsp;{{item.name}}</span>
                  </template>
                  <template #right-icon>
                    <van-radio
                      :name="item.id"
                      checked-color="#94FF75"
                    />
                  </template>
                </van-cell>

              </van-cell-group>
            </van-radio-group>
          </div>
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              :color="variables.textColor"
              @click="onSub()"
            >确定</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-popup
      v-model="cardShow"
      @click="cardPopup"
      custom-class='card-popub'
      :style="{ padding: '10px 0px', width: '88%','background-color':'transparent'}"
    >
      <div class="card-blog">

        <img src="../../assets/image/dloag_banner.png">
      </div>
    </van-popup>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import variables from "@/styles/variables.scss";
import API from "../../api/http";

export default {
  components: { HeaderBack },
  data() {
    return {
      allMOney: "",
      valueMoney: "",
      freezeBalance: "",
      staticFreezeBalance: 0,
      // card: utils.setIdCard("1234567890"),
      card: "",
      cardId: "",
      cardList: [
        {
          value: "￥100.00",
          label: "2022年2月15日",
          title: "银行卡提现***5233（对公）",
        },
      ],

      show: false,
      typsList: [
        {
          icon: "hotel-o",
          name: "对公提现",
          id: "1",
        },
        {
          icon: "contact",
          name: "对私提现",
          id: "2",
        },
      ],
      typsList2: [
        {
          icon: "card",
          name: "1234567",
          id: "1",
          img: require("../../assets/image/person/bank.png"),
        },
        {
          icon: "alipay",
          name: "1234567",
          id: "2",
          img: require("../../assets/image/person/pay1.png"),
        },
      ],
      radio: "1",
      showPopover: false,
      address: "1",
      isDisable: false,
      cardShow: false,
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    this.getTime();
    this.getMOney();
    if (utils.getStore("bank")) {
      let bank = JSON.parse(utils.getStore("bank"));
      this.cardId = bank.cardId;
      this.card = bank.number;
    } else {
      this.getInit();
    }
  },
  methods: {
    getMOney() {
      //获取提现金额
      API.getUserProfile().then((res) => {
        this.allMOney = Math.floor(res.data.balance);
        this.freezeBalance = res.data.freezeBalance;
        this.staticFreezeBalance = res.data.staticFreezeBalance;
      });
    },
    getAddress() {
      let that = this;
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          that.getCity(r.point);
        }
      });
      return new Promise((resolve) => {
        setTimeout(() => resolve(that.address), 1000);
      });
    },
    getCity(point) {
      let that = this;
      var point = new BMap.Point(point.lng, point.lat); //用所定位的经纬度查找所在地省市街道等信息
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function (rs) {
        // var addComp = rs.addressComponents;

        that.address = rs.address;
      });
    },
    getInit() {
      //获取添加过的银行列表
      API.getUserBankList().then((res) => {
        let result = res.data;
        if (result.list.length > 0) {
          this.card = result.list[0].bankNumber;
          this.cardId = result.list[0].id;
        }
      });
    },
    radioClick(item) {
      this.radio = item.id;
    },
    onSub() {
      this.show = false;
    },
    onCard() {
      // 立即绑卡
      this.$router.push("/bankCard");
    },
    onMoneyChange(val) {
      if (val > this.allMOney) {
        Toast("提现金额大于总金额");
        return;
      }
    },
    onAllMoney() {
      this.valueMoney = this.allMOney;
    },
    setTime(time) {
      let date, year, month, day, h, m, s;
      date = new Date();
      if (time) {
        date = new Date(time);
      }

      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
      h = date.getHours();
      m = date.getMinutes();
      s = date.getSeconds();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return {
        year,
        month,
        day,
        h,
        m,
        s,
      };
    },
    getTime() {
      let date1 = this.setTime();
      //今天
      let m1 = new Date(`${date1.year}/${date1.month}/${date1.day} 16:00:00`);
      //明天
      // let curData = new Date();
      // let time1 = curData.setTime(curData.getTime() + 24 * 60 * 60 * 1000);
      // let date2 = this.setTime(time1);
      let m2 = new Date(`${date1.year}/${date1.month}/${date1.day} 08:00:00`);

      let newDate = new Date();

      if (
        newDate.getTime() < m1.getTime() &&
        newDate.getTime() > m2.getTime()
      ) {
        return true;
      }
      return false;
    },
    onCardClick() {
      this.cardShow = true;
    },
    cardPopup() {
      this.cardShow = false;
    },
    async onSumClick() {
      console.log("kkk", this.getTime());
      // if (!this.getTime()) {
      //   Toast("每天16：00到次日08：00点之间不可提现");
      //   return;
      // }
      //确认提现
      if (!this.card) {
        Toast("请先绑定银行");
        return;
      }
      console.log();
      if (!this.valueMoney) {
        Toast("请输入提现金额");
        return;
      }
      if (this.valueMoney < 100) {
        Toast("提现金额需>=100");
        return;
      }
      if (this.valueMoney > this.allMOney) {
        Toast("提现金额大于总金额");
        return;
      }
      // if (!this.address) {
      //   this.address = await this.getAddress();
      // }
      if (this.isDisable) {
        return;
      }
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 3000);
      let data = {
        userBankId: this.cardId,
        amount: this.valueMoney,
        type: 0,
        location: this.address,
      };

      API.postSubWithdraw(data).then((res) => {
        Toast.success("提交成功");
        this.$router.push("/withdrawSuc");
        // this.allMOney = this.allMOney - this.valueMoney;
        // this.valueMoney = "";

        // this.getMOney();
      });
      // this.show = true;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.block {
  width: 70%;
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
  .wrp-tit {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .wrp-cen {
    padding: 0 30px;
    box-sizing: border-box;
  }
}
.with-m,
.with-c {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  margin-top: 16px;
  .van-cell .van-icon {
    vertical-align: middle;
  }
}

.with-c .van-cell .van-cell__value {
  // text-align: left;
  color: $textColor;
}
.with-c {
  .van-cell-group__title {
    font-weight: 500;
    color: #474747;
  }
  .van-cell > .van-field__label {
    width: 1em;
  }
  .with-c-t {
    border-top: 1px solid #ebedf0;
    font-size: 12px;
    .with-c-money {
      color: $textColor;
    }
    .with-c-rt {
      color: #969799;
    }
  }
  .van-cell-group > .van-cell {
    padding: 16px;
    .van-cell__label {
      padding-left: 0.7rem;
    }
  }
}
.with-span {
  color: $textColor;
  cursor: pointer;
  margin-left: 10px;
}
.with-m {
  .van-cell-text {
    color: #737373;
  }
  .van-cell {
    padding: 16px;
  }
}
.with-img {
  width: 100%;
  margin-top: 16px;
  img {
    width: 100%;
  }
}
.with-btn {
  width: 100%;
  margin-top: 10px;
}
.card .van-cell__left-icon-wrap {
  color: $textColor;
}
.with-popover {
  font-size: 12px;
  padding: 10px;

  p {
    color: #333333;
    font-size: 16px;
    text-align: center;
    padding-bottom: 10px;
  }
  li {
    color: #999999;
    line-height: 20px;
  }
}
.card-blog {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}
.card-popub {
}
</style>
